import { oom } from "@mixitone/oom";
import SupabaseDatabaseAdapter from "@mixitone/oom/adapters/SupabaseDatabaseAdapter";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { App } from "./App";
import { Authenticated } from "./Components/Authenticated";
import { ActivationPage } from "./Pages/ActivationPage";
import { ClubDetailsPage } from "./Pages/ClubDetailsPage";
import { LandingPage } from "./Pages/LandingPage";
import { MagicLinkPage } from "./Pages/MagicLinkPage";
import { ProfilePage } from "./Pages/ProfilePage";
import { TagLandingPage } from "./Pages/TagLandingPage";
import "./index.css";
import { createSupabase } from "./supabaseClient";

const supabase = createSupabase();
oom.configure({ adapter: new SupabaseDatabaseAdapter(supabase) });

if (import.meta.env.VITE_TEST !== "1") {
  Sentry.init({
    dsn: "https://d41418b5878b8c2d9a7b15c5265125b3@o1057895.ingest.us.sentry.io/4508153772572672",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.3, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.MODE,
    enabled: true, //import.meta.env.MODE !== "development",
  });
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <ActivationPage />,
      },
      {
        path: "/magic/:token",
        element: <MagicLinkPage />,
      },
      {
        path: "tag/:tag",
        element: <TagLandingPage />,
      },
      {
        path: "in",
        element: <Authenticated />,
        children: [
          {
            path: "home",
            element: <LandingPage />,
          },
          {
            path: "profile",
            element: <ProfilePage />,
          },
          {
            path: "club/:clubId",
            element: <ClubDetailsPage />,
          },
        ],
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(<RouterProvider router={router} />);
